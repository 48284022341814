<!--footer section -->
<footer [class]="class">
	
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>¡ Mantengase Informado !</h4>
								<p>Obtenga las últimas noticias sobre nuestros productos, ofertas exclusivas y consejos ...</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" >
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Escriba su email">
							</div>
							<button type="submit" class="btn btn-solid">Subscribirse</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<div style="background-color: #e4e2d7;padding-top: 70px;"></div>
	<section class="section-b-space light-layout" style="background-color: #d1cfc1;color:black">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>Sobre nosotros</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" class="imagenlogo">
						</div>
						<p style="color:black;line-height: 22px;font-weight: 800;font-size: 16px;">Oficinas Centrales</p>
						<p style="color:black;line-height: 22px;">C/ Camproig, 1</p>
						<p style="color:black;line-height: 22px;">Polígon industrial Capdepera · 07580 Capdepera</p>
						<p style="color:black;line-height: 22px;">Illes Balears · España</p>
						<p style="color:black;line-height: 22px;"><span class="material-symbols-outlined" style="margin-right: 20px;">
							call
							</span>0034 695 275 124</p>
							<p style="color:black;line-height: 22px;"><a href="mailto:info@oliart.es" style="text-decoration: none;font-size: inherit;color:inherit"><span class="material-symbols-outlined" style="margin-right: 20px;">
							mail
							</span>info&#64;oliart.es</a></p>

						<div class="footer-social" style="color:black">
                           <ul>
                               <!--  <li>
                                    <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li> -->
                               <!--  <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>-->
                               <!--  <li> 
                                    <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li> -->
                               <!--  <li>
                                    <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li> -->
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title" style="padding-top: 30px;">
                            <h4>Secciones</h4>
                        </div>
                        <div class="footer-contant">
                           <!--  <ul>
                                <li><a href="javascript:void(0)">Womens Fashion</a></li>
                                <li><a href="javascript:void(0)">Mens Fashion</a></li>
                                <li><a href="javascript:void(0)">Kids Fashion</a></li>
                                <li><a href="javascript:void(0)">Featured</a></li>
                            </ul> -->
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title" style="padding-top: 30px;">
                            <h4>Otros enlaces</h4>
                        </div>
                        <div class="footer-contant" style="color:black">
							<ul>
								<li > <a  (click)="navigateTo('pages/politica-privacidad')"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" style="color:black">Política de privacidad</a></li>
								<li > <a  (click)="navigateTo('pages/politica-cookies')"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" style="color:black">Política de cookies</a></li>
								<li > <a  (click)="navigateTo('pages/aviso-legal')"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" style="color:black">Aviso legal</a></li>
								
							  </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<!-- <div class="footer-title">
						    <h4>store information</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>Multikart Demo Store, Demo store
						            India 345-659</li>
						        <li><i class="fa fa-phone"></i>Call Us: 123-456-7898</li>
						        <li><i class="fa fa-envelope-o"></i>Email Us: <a>Support&#64;Fiot.com</a></li>
						        <li><i class="fa fa-fax"></i>Fax: 123456</li>
						    </ul>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer"  style="background-color: white;">
		<div class="container" >
			<div class="row" >
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}}</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                   <!--  <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul> -->
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->