import { HttpClient } from '@angular/common/http';
import { Injectable, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Textos } from '../data/textos';
import { Contacto } from '../data/contacto';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}



@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor(private http: HttpClient, private router:Router) {


	 }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;
	public baseUrl=environment.apiUrl;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [];
	TEXTOS: Textos[] = []
	/*[
		 {
			path: '/home/', title: 'Home', type: 'link'
		},
		{
			title: 'Oliart', type: 'sub', active: true, children: [
				{ path: '/home/', title: 'Nuestra historia', type: 'link' },
				{ path: '/home/', title: 'Nuestra finca', type: 'link' },
				{ path: '/home/', title: 'Procesos', type: 'link' },
				{ path: '/home/', title: 'AOVE ecológico', type: 'link' },		
				
			]
		},
		{
			title: 'Nuestros aceites', type: 'sub', active: true, children: [
				
				{ path: '/home/', title: 'Oliart', type: 'link' },
				{ path: '/home/', title: 'Finca rocamel·la', type: 'link' },
					
			]
		},
		{
			path: '/galeria/', title: 'Galería', type: 'link'
		},
		{
			path: '/galeria/', title: 'Contacto', type: 'link'
		}

	]; */

	LEFTMENUITEMS: Menu[] = [
	
			
	];

	// Array

	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);


	private items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	private textos = new BehaviorSubject<Textos[]>(this.TEXTOS);

	public GetMenu() {       
               
        let idioma:string;
      
        if(localStorage.getItem("idioma")){
            idioma=localStorage.getItem("idioma")!;
        }
        else{  
            idioma='1';
            localStorage.setItem('idioma',idioma);  
        }
       
        console.log("peticion a "+this.baseUrl + 'getmenu.php');
      return this.http.post<any>(this.baseUrl + 'getmenu.php', { params: idioma })
      .pipe(
        map(opcionesMenu => {            
          // Update the BehaviorSubject with new data
          this.items.next(opcionesMenu); // Notify subscribers

          console.log("opcionesmenu", opcionesMenu);
          localStorage.setItem('menu', JSON.stringify(opcionesMenu));  
          
          return opcionesMenu;
        })
      );        
     }


	 
	public GetTextos() {       
               
        let idioma:string;
      
        if(localStorage.getItem("idioma")){
            idioma=localStorage.getItem("idioma")!;
        }
        else{  
            idioma='1';
            localStorage.setItem('idioma',idioma);  
        }
       console.log("textos","service","request","idioma",idioma);
       
      return this.http.post<any>(this.baseUrl + 'gettextes.php', { params: idioma })
      .pipe(
        map(textosReponse => {            
          // Update the BehaviorSubject with new data
		  console.log("textos","service","response",textosReponse);
          this.textos.next(textosReponse); // Notify subscribers

          console.log("textos", textosReponse);
          localStorage.setItem('textos', JSON.stringify(textosReponse));  
          
          return textosReponse;
        })
      );        
     }

	 public getItems() {
		return this.items.asObservable();
	  }


	  public getTextos() {
		return this.textos.asObservable();
	  }


	  public GetGaleriaImages(item:string, idioma:string) {      
        
      
		return this.http.post<any>(this.baseUrl + 'get_galeria_images_front.php',{item,idioma})
		.pipe(map(item => {
		 
		return item;
		}));        
	 }

	 public SendContact( fitxa :any) {
      console.log("enviamos",fitxa);
        return this.http.post<any>(this.baseUrl + 'envio_contacto.php',{  fitxa})
        .pipe(map(
            res=>{
                //console.log(res);
            })
        );        
     }
	
}
