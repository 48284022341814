import { Component, OnInit, Injectable, PLATFORM_ID, Inject, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, concatMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public products: Product[] = [];
  public search: boolean = false;
  public isSticky: boolean = false;
  public idioma:string;
  public codIdioma:string;

  public languages = [{ 
    name: 'Español',
    code: 'ES'
  }/*, {
    name: 'English',
    code: 'EN'
  }, {
    name: 'Deutsch',
    code: 'DE'
  } ,{
    name: 'Català',
    code: 'CA'
  }*/];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }]

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService,public navServices: NavService) {
   
      this.codIdioma="ES";

      this.navServices.getItems().subscribe(items => {
        
        if(localStorage.getItem('idioma')){        
          this.idioma=localStorage.getItem('idioma');
          if(this.idioma==='1')
            this.codIdioma="ES";
          if(this.idioma==='2')
            this.codIdioma="EN";
          if(this.idioma==='3')
            this.codIdioma="DE";
          if(this.idioma==='4')
            this.codIdioma="CA";

        }
        else{
          this.idioma="1";
          this.codIdioma="ES";
        }

      });
  
      // Optionally call GetMenu if you want to fetch/update the menu on initialization
      this.navServices.GetMenu().subscribe(items =>{
         this.navServices.getTextos().subscribe();
      });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const header = document.querySelector('header'); // Assuming 'header' is the element with .sticky and .fixed
    if (header?.classList.contains('sticky') && header?.classList.contains('fixed')) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }
  
  ngOnInit(): void {
  }

  searchToggle(){
    this.search = !this.search;
  }

  changeLanguage(code){
let id:string;

    if(code==='ES')
      {
        id="1";
      }
      if(code==='EN')
        {
          id="2";
        }
        if(code==='DE')
          {
            id="3";
          }
      if(code==='CA')
        {
          id="4";
        }
        localStorage.setItem('idioma',id);  

     
    
        // Optionally call GetMenu if you want to fetch/update the menu on initialization
      //  this.navServices.GetMenu().subscribe();
       // this.navServices.GetTextos().subscribe();

       this.navServices.GetMenu().subscribe(items =>{
        this.navServices.GetTextos().subscribe();
     });
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

}
